import { useEffect, useRef, useState } from "react";

import { useDevice } from "./../useDevice";
import useFetchDeliveryDetails, { ILocation } from "../useFetchDeliveryDetails";
import useFetchListingDetails from "../useFetchListingDetails";
import { useGlobalSelector } from "../useGlobalSelector";
import { useGeoLocation } from "../useGeolocation";
import { ListingReducerType } from "../../store/forexListingReducer";
import { LocationFetchingProps } from "../../types";
import { useGlobalConfig } from "../useGlobalConfig";
import {
  deleteCookiesWithPrefix,
  generateRequestId,
  getEventTrackingContext,
  getHostName,
  getPartner,
  isAgentFlow,
} from "../../utils";
import { getSessionStorageItem } from "../../utils/clientStorageUtils";
import { getCookie } from "../../utils/cookieUtils";
import {
  HOST,
  SESSION_STORAGE_KEYS,
  WAITING_TIME_LOC_SUCCESS_SCREEN,
  SUB_DOMAIN,
  COOKIES,
  getSessionPiiDataOpts,
  VERSIONS,
} from "../../constants";
import { useManageBodyScrollForLocFetching } from "../useManageBodyScrollForLocFetching";
import { useListingPdtLogger } from "../usePdtLogger";
import { trackPageLoad, PAGE_NAMES } from "../../analytics";
import useListingPdtLoggerV2 from "../useListingPdtLoggerV2";
import { IEventTrackingContext } from "../../utils/pdtutils/types";

export type ScreenType = "LISTING_PAGE" | "EDIT_LOCATION";
const { MMT_FOREX_COOKIE } = COOKIES;

const useFetchListingPostLocationChange = (
  isLoggedInUser: boolean | null,
  listingDetailsReqIdRef: any
) => {
  const timerRef = useRef<number | NodeJS.Timeout | undefined>();
  const { partner, isBot } = useGlobalConfig();
  const device = useDevice();
  const {
    products,
    persona,
    isSuccessDeliveryDetails,
    isLoadingDeliveryDetails,
    isErrorDeliveryDetails,
    delivery_details,
    isSuccessListingDetailsOnLocChange,
    tm_user_id,
    experiments,
  } = useGlobalSelector((state: ListingReducerType) => state || {});
  const { fetchListingDetailsOnLocationChange } = useFetchListingDetails();
  const { sendListingPdtLocationDetectedViewEvent } = useListingPdtLogger();
  const { sendListingPdtLocationDetectedViewEventV2, sendPdtDataV2 } =
    useListingPdtLoggerV2();

  const { upcoming_trip: upcomingTrip } = persona || {};
  const {
    location: { latitude, longitude },
    isLoading,
    isSuccess,
    isError,
    setUserLocation,
    setUserLocationIfPermitted,
  } = useGeoLocation(partner);

  const userLocation = getSessionStorageItem(
    SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
    true,
    getSessionPiiDataOpts
  );

  const isProductFetched = !!(products && products?.length > 0);
  const host = getHostName();
  const [isLocationFetching, setIsLocationFetching] = useState(
    isProductFetched || host === HOST.MAKEMYTRIP ? false : !userLocation
  );
  const [isPageLoading, setIsPageLoading] = useState(
    isProductFetched
      ? false
      : !!userLocation || (host === HOST.MAKEMYTRIP && !isProductFetched)
  );
  const [locFetchingMode, setLocFetchingMode] =
    useState<LocationFetchingProps>("FETCHING");

  const [showEmailLoginPopup, setShowEmailLoginPopup] = useState(false);
  const [shouldfetchLocFromSession, setShouldfetchLocFromSession] =
    useState(true);

  useManageBodyScrollForLocFetching(isPageLoading, isLocationFetching);
  const deliveryDetailsReqIdRef = useRef(generateRequestId());

  const requestBody: ILocation =
    process.env["NX_ENV"] === "local"
      ? {
          reqId: deliveryDetailsReqIdRef.current,
          latitude: 12,
          longitude: 12,
        }
      : {
          reqId: deliveryDetailsReqIdRef.current,
          latitude,
          longitude,
          ...delivery_details,
        };
  const { fetchUserDeliveryInfo } = useFetchDeliveryDetails(requestBody);

  useEffect(() => () => clearTimeout(timerRef.current), []);

  useEffect(() => {
    const mmtRedirectionCookie = getCookie(MMT_FOREX_COOKIE, true) || "";
    if (mmtRedirectionCookie)
      deleteCookiesWithPrefix(MMT_FOREX_COOKIE, Object.values(SUB_DOMAIN));
  }, []);

  useEffect(() => {
    if (isSuccessListingDetailsOnLocChange) {
      trackPageLoad({
        pageName: PAGE_NAMES["LISTING"],
        partner: getPartner(partner),
        tmUserId: tm_user_id,
        upcomingTrip,
      });
    }
  }, [partner, tm_user_id, isSuccessListingDetailsOnLocChange]);

  useEffect(() => {
    if (host === HOST.MAKEMYTRIP) {
      if (products && products?.length > 0) {
        setIsPageLoading(false);
      } else {
        setIsPageLoading(true);
      }
    }
  }, [products, host]);

  useEffect(() => {
    if (isBot || host === HOST.MAKEMYTRIP) {
      return;
    }
    if (
      (userLocation && Number(userLocation?.eta_in_days) > 1) ||
      (device === "lg" && !isAgentFlow())
    ) {
      (async () => {
        setIsPageLoading(true);
        if (isLoggedInUser == null) return;
        listingDetailsReqIdRef.current = generateRequestId();
        await fetchListingDetailsOnLocationChange(null, userLocation, {
          reqId: listingDetailsReqIdRef.current,
        });
        setIsPageLoading(false);
      })();
      return;
    }
    const currentDate = new Date();
    if (
      Number(userLocation?.eta_in_days) === 0 &&
      currentDate.getHours() >= 13 &&
      currentDate.getMinutes() > 0
    ) {
      setShouldfetchLocFromSession(false);
    }
    if (
      userLocation &&
      Number(userLocation?.eta_in_days) === 1 &&
      currentDate.getHours() < 13
    ) {
      setShouldfetchLocFromSession(false);
    }
    if (device === "sm" || isAgentFlow()) {
      setUserLocationIfPermitted();
      return;
    }
  }, [device, isBot, host, isLoggedInUser]);

  useEffect(() => {
    if (host === HOST.MAKEMYTRIP) {
      setIsLocationFetching(false);
      return;
    }
    let permission_details: string = "";
    let location_source: string = "";
    if (isSuccess) {
      permission_details = "allowed";
      location_source = "app";
    }
    if (isError) {
      permission_details = "denied";
      location_source = "akamai";
    }
    if ((isError || isSuccess) && (device === "sm" || isAgentFlow())) {
      (async () => {
        setIsLocationFetching(true);
        if (isLoggedInUser == null) return;
        setLocFetchingMode("FETCHING");
        const { request_id, userLocationData, userLocationSession } =
          (await fetchUserDeliveryInfo(shouldfetchLocFromSession)) || {};

        if (request_id || userLocationData || userLocationSession) {
          setLocFetchingMode("SUCCESS");
        } else {
          setLocFetchingMode("ERROR");
        }
        timerRef.current = setTimeout(() => {
          setIsLocationFetching(false);
        }, WAITING_TIME_LOC_SUCCESS_SCREEN);
        const deliveryDetails = {
          ...requestBody,
          ...userLocationData,
          ...userLocationSession,
        };

        setIsPageLoading(true);

        listingDetailsReqIdRef.current = generateRequestId();
        const response = await fetchListingDetailsOnLocationChange(
          request_id,
          deliveryDetails,
          {
            reqId: listingDetailsReqIdRef.current,
          }
        );
        setIsPageLoading(false);

        sendListingPdtLocationDetectedViewEvent(
          location_source,
          permission_details,
          response?.listingData?.version || VERSIONS.V2,
          response?.listingData?.tm_user_id,
          response?.listingData?.puid
        );
      })();
    }
  }, [isSuccess, isError, device, isLoggedInUser]);

  useEffect(() => {
    if (isSuccessDeliveryDetails) {
      sendListingPdtLocationDetectedViewEventV2({
        eventTrackingContext: getEventTrackingContext({
          request_id: deliveryDetailsReqIdRef.current,
        } as IEventTrackingContext),
      });
    }
  }, [isSuccessDeliveryDetails]);

  return {
    isLocationFetching,
    isPageLoading,
    locFetchingMode,
    isLoading,
    isSuccess,
    isLoadingDeliveryDetails,
    isSuccessDeliveryDetails,
    isErrorDeliveryDetails,
    setUserLocation,
    setUserLocationIfPermitted,
    fetchUserDeliveryInfo,
    showEmailLoginPopup,
    setShowEmailLoginPopup,
  };
};

export default useFetchListingPostLocationChange;
