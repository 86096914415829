import { useEffect, useState } from "react";
import {
  BaseStyles,
  FontStyles,
  TripMoneyStyles,
  MMTLogo,
} from "../CurrencySEOCommonUI";
import TMLogo from "../TMLogo";
import BmfLogo from "../BMFLogo";
import { CurrencySEOMarkdown } from "../CurrencySEOMarkdown";
import { CityFooter } from "./CityFooter";
import CityDocumet from "./CityDocumet";
import { Hero } from "../CurrencyPageTemplate/Hero";
import CurrencyConverter from "../CurrencyRootPageTemplate/CurrencyConverter";
import { HeroV2 } from "../CurrencyPageTemplate/HeroV2";
import Faqs from "../CurrencyConverterPageTemplate/Faqs";
import ForexProduct from "../CurrencyConverterPageTemplate/ForexProducts";
import { PAGE_NAMES, trackSeoPageLoad } from "@tm/utility/analytics";

export const CurrencyCityTemplate = ({
  cityData,
  isMmtHost,
  currency_exchange_in_city,
  templateType,
}: any) => {
  const [scrolled, setScrolled] = useState(false);
  const [showViewMore, setShowViewMore] = useState<boolean>(true);
  const [cardsToBeShown, setCardsToBeShown] = useState<number>(4);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    trackSeoPageLoad({
      pageName: PAGE_NAMES[`SEO_CURRENCY_EXCHANGE_${templateType}`],
      city: cityData?.City,
    });
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const {
    hero_btn_link: btn_link,
    hero_btn_text: btn_text,
    hero_img_link: img_link,
    hero_desc: desc,
    hero_title: title,
    hero_list: list,
    hero_img: forex_sol,
    hero_img_mobile: forex_sol_mobile,
  } = cityData || {};

  const heroProps: React.ComponentProps<typeof Hero> = {
    btn_link,
    btn_text,
    img_link,
    desc,
    title,
    list,
    forex_sol,
    forex_sol_mobile,
    green_check: cityData?.green_check,
  };

  const handleOnClick = () => {
    setShowViewMore(false);
    setCardsToBeShown(14);
  };

  return (
    <>
      <FontStyles />
      <BaseStyles />
      <TripMoneyStyles />

      <CityDocumet
        cityData={cityData}
        currency_exchange_in_city={currency_exchange_in_city}
      />

      <>
        <header>
          <div className="header__inner">
            <a href={cityData?.header?.image_link}>
              {isMmtHost ? (
                <MMTLogo isLight={scrolled ? false : true} />
              ) : (
                <BmfLogo isLight={scrolled ? false : true} />
              )}
            </a>
          </div>
        </header>

        <div className="topBlueSection" />

        <div className="greySection">
          <div className="container negativeMargin">
            <HeroV2 {...heroProps} breadcrumb={cityData?.breadcrumb} />
            <div className="pageContainer pageContainer_margin sectionGapping">
              <ForexProduct
                currencyData={cityData}
                pageName={PAGE_NAMES[`SEO_CURRENCY_EXCHANGE_${templateType}`]}
              />
            </div>
            {cityData?.Description_title ? (
              <section className="tripMoneySection makeFlex hrtlCenter">
                <h1 className="bannerText">{cityData?.Page_title}</h1>
              </section>
            ) : null}

            {cityData?.Description_desc ? (
              <section className="tripMoneySection">
                <h2 className="appendBottom20">
                  {cityData?.Description_title
                    ? cityData?.Description_title
                    : ""}
                </h2>
                <CurrencySEOMarkdown>
                  {cityData?.Description_desc}
                </CurrencySEOMarkdown>
                <CurrencySEOMarkdown>
                  {cityData?.Description_desc_addendum}
                </CurrencySEOMarkdown>
              </section>
            ) : null}
          </div>
        </div>

        {cityData?.All_Fake_currencies_way_ ? (
          <div className="whiteSection">
            <div className="container">
              <section className="tripMoneySection makeFlex">
                <div>
                  <h2 className="appendBottom30">
                    {cityData?.Fake_currencies_title}
                  </h2>
                  <div className="counterFietImgWrapMweb">
                    <img
                      {...cityData?.fake_currency_mobile_logo}
                      loading="lazy"
                    />
                  </div>
                  <CurrencySEOMarkdown className="appendBottom20">
                    {cityData?.Fake_currencies_desc}
                  </CurrencySEOMarkdown>
                  {cityData?.All_Fake_currencies_way_ ? (
                    <ul>
                      {cityData?.All_Fake_currencies_way_.map(
                        (way: any, index: number) => (
                          <li
                            key={way}
                            className={`makeFlex ${
                              index <
                              cityData?.All_Fake_currencies_way_.length - 1
                                ? "dashedBorder"
                                : ""
                            }`}
                          >
                            <img
                              className="greenCheckImg"
                              {...cityData?.green_check}
                              loading="eager"
                            />
                            <span>
                              <CurrencySEOMarkdown>{way}</CurrencySEOMarkdown>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  ) : null}
                </div>
                {cityData?.fake_currency_logo ? (
                  <div className="counterFietImgWrap">
                    <img {...cityData?.fake_currency_logo} loading="lazy" />
                  </div>
                ) : (
                  <></>
                )}
              </section>
            </div>
          </div>
        ) : null}

        <div className="greySection">
          <div className="container">
            <section className="tripMoneySection">
              <div className="container__card">
                {cityData?.All_Major_areas_ && cityData?.All_Other_areas_ ? (
                  <div key="Major areas">
                    <h3 className="appendBottom18">
                      {cityData?.Major_areas_title}
                    </h3>
                    <CurrencySEOMarkdown className="appendBottom10">
                      {cityData?.Major_areas_desc}
                    </CurrencySEOMarkdown>
                    <ul>
                      {cityData?.All_Major_areas_?.map(
                        (area: any, index: number) => (
                          <li key={area} className="makeFlex appendBottom15">
                            <img
                              className="greenCheckImg"
                              {...cityData?.green_check}
                              loading="eager"
                            />
                            <span>
                              <CurrencySEOMarkdown>{area}</CurrencySEOMarkdown>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                    <div className="dashedBorder" />

                    <h3 className="appendBottom18">
                      {cityData?.Other_areas_title}
                    </h3>
                    <ul>
                      {cityData?.All_Other_areas_?.map(
                        (area: any, index: number) => (
                          <li key={area} className="makeFlex appendBottom15">
                            <img
                              className="greenCheckImg"
                              {...cityData?.green_check}
                              loading="eager"
                            />
                            <span>
                              <CurrencySEOMarkdown>{area}</CurrencySEOMarkdown>
                            </span>
                          </li>
                        )
                      )}
                    </ul>

                    <div className="dashedBorder" />
                  </div>
                ) : null}

                {cityData?.All_Famous_place_ && cityData?.All_Other_place_ ? (
                  <div key="Famous places">
                    <h3 className="appendBottom18">
                      {cityData?.Famous_place_title}
                    </h3>
                    <CurrencySEOMarkdown className="appendBottom10">
                      {cityData?.Famous_place_desc}
                    </CurrencySEOMarkdown>
                    <ul>
                      {cityData?.All_Famous_place_?.map(
                        (place: any, index: number) => (
                          <li key={place} className="makeFlex appendBottom15">
                            <img
                              className="greenCheckImg"
                              {...cityData?.green_check}
                              loading="eager"
                            />
                            <span>
                              <CurrencySEOMarkdown>{place}</CurrencySEOMarkdown>
                            </span>
                          </li>
                        )
                      )}
                    </ul>

                    {cityData?.All_Other_place_ ? (
                      <>
                        <div className="dashedBorder" />
                        <h3 className="appendBottom18">
                          {cityData?.Other_place_title}
                        </h3>
                        <ul>
                          {cityData?.All_Other_place_?.map((place: any) => (
                            <li key={place} className="makeFlex appendBottom15">
                              <img
                                className="greenCheckImg"
                                {...cityData?.green_check}
                                loading="eager"
                              />
                              <span>
                                <CurrencySEOMarkdown>
                                  {place}
                                </CurrencySEOMarkdown>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : null}
              </div>
            </section>

            {cityData?.All_Towns_and_cities_ && cityData?.All_Other_cities_ ? (
              <section className="tripMoneySection">
                <div className="container__card appendBottom80">
                  <img
                    className="containerImg"
                    {...cityData?.towns_cities_img}
                    loading="lazy"
                  />
                  <h3 className="appendBottom30">
                    {cityData?.Towns_and_cities_title}
                  </h3>
                  <ul className="productRangeList">
                    {cityData?.All_Towns_and_cities_?.map((city: any) => (
                      <li key={city}>
                        <CurrencySEOMarkdown>{city}</CurrencySEOMarkdown>
                      </li>
                    ))}
                  </ul>
                  <div className="dashedBorder" />
                  <h3 className="appendBottom18">
                    {cityData?.Other_cities_title}
                  </h3>
                  <ul>
                    {cityData?.All_Other_cities_?.map((city: any) => (
                      <li key={city} className="makeFlex appendBottom15">
                        <img
                          className="greenCheckImg"
                          {...cityData?.green_check}
                          loading="eager"
                        />
                        <span>
                          <CurrencySEOMarkdown>{city}</CurrencySEOMarkdown>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
            ) : null}

            {cityData?.All_Nearest_airports_ ? (
              <section className="tripMoneySection">
                <h2 className="appendBottom20">
                  {cityData?.Nearest_airports_title}
                </h2>
                {cityData?.All_Nearest_airports_?.map((airport: any) => (
                  <CurrencySEOMarkdown>{airport}</CurrencySEOMarkdown>
                ))}
              </section>
            ) : null}

            {cityData?.All_Carry_currency_way_ ? (
              <section className="tripMoneySection">
                <h2 className="appendBottom20">
                  {cityData?.Carry_currency_title}
                </h2>
                <CurrencySEOMarkdown className="appendBottom24">
                  {cityData?.Carry_currency_desc}
                </CurrencySEOMarkdown>
                <div className="container__card container__cardAlign">
                  <div className="tripmoneyImgWrap">
                    <img
                      className="tripMoneyImg"
                      {...cityData?.best_ways_cary_logo}
                      loading="lazy"
                    />
                  </div>
                  <div>
                    {cityData?.All_Carry_currency_way_?.map((way: any) => (
                      <div key={way}>
                        <CurrencySEOMarkdown className="blackText appendBottom10">
                          {way}
                        </CurrencySEOMarkdown>
                        <div className="dashedBorder" />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            ) : null}

            {cityData?.All_Exchange_currency_steps_ ? (
              <section className="tripMoneySection">
                <h2 className="appendBottom20">
                  {cityData?.Exchange_currency_title}
                </h2>
                <CurrencySEOMarkdown className="appendBottom24">
                  {cityData?.Exchange_currency_desc}
                </CurrencySEOMarkdown>
                <ul className="tripMoneySection__bestRateBuy">
                  {cityData?.All_Exchange_currency_steps_?.map(
                    (step: any, index: number) => (
                      <li key={step}>
                        <span className="stepCount">
                          <span className="font16">STEP</span>{" "}
                          {index + 1 < 9
                            ? (index + 1).toString().padStart(2, "0")
                            : index + 1}
                        </span>
                        <CurrencySEOMarkdown>{step}</CurrencySEOMarkdown>
                      </li>
                    )
                  )}
                </ul>
                {cityData?.Buy_sell_local_title ? (
                  <div className="container__card">
                    <h3 className="appendBottom20">
                      {cityData?.Buy_sell_local_title}
                    </h3>
                    <CurrencySEOMarkdown className="appendBottom20">
                      {cityData?.Buy_sell_local_desc}
                    </CurrencySEOMarkdown>
                  </div>
                ) : (
                  <></>
                )}
              </section>
            ) : null}

            {cityData?.All_Purpose_ ? (
              <section className="tripMoneySection">
                <div className="exchangeRateWrap appendBottom35">
                  {cityData?.All_Purpose_ ? (
                    <div className="container__card flexOne">
                      <h2 className="appendBottom22">
                        {cityData?.Purpose_for_exchanging_title}
                      </h2>

                      <ul className="productRangeList">
                        {cityData?.All_Purpose_?.map((purpose: any) => (
                          <li key={purpose} className="dashedBorder">
                            <CurrencySEOMarkdown>{purpose}</CurrencySEOMarkdown>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}

                  <div className="flexOne makeFlex column">
                    <div className="container__card appendBottom30">
                      <h2 className="appendBottom22">
                        {cityData?.Buy_sell_online_title}
                      </h2>
                      <CurrencySEOMarkdown>
                        {cityData?.Buy_sell_online_desc}
                      </CurrencySEOMarkdown>
                    </div>
                    <div className="container__card">
                      <h2 className="appendBottom22">
                        {cityData?.Home_delivery_title}
                      </h2>
                      <CurrencySEOMarkdown>
                        {cityData?.Home_delivery_desc}
                      </CurrencySEOMarkdown>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <></>
            )}

            <CurrencyConverter
              currConversion={cityData?.currency_conversions}
            />
            <Faqs currencyData={cityData} />
          </div>
        </div>
        {cityData?.footer ? (
          <CityFooter footer={cityData?.footer} city={cityData?.City} />
        ) : null}
      </>
    </>
  );
};
